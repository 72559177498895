import React, { useCallback } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import { categoriesKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageMaterialsCategoriesNavAmountButtonProps {
  showAll: boolean;
  setShowAll: (val: boolean) => void;
}

function ThreeDStockIndexPageMaterialsCategoriesNavAmountButton({
  showAll,
  setShowAll
}: ThreeDStockIndexPageMaterialsCategoriesNavAmountButtonProps) {
  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, [setShowAll]);

  const handleHide = useCallback(() => {
    setShowAll(false);
  }, [setShowAll]);

  return (
    <div className="-mx-4">
      <ButtonHelper
        className="bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 text-gray-900 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-100 w-full flex pl-4 pr-2 py-2 text-sm"
        dataGa={`3d-stock-menu-materials-categories-${
          showAll ? 'seeLess' : 'seeAll'
        }`}
        onClick={showAll ? handleHide : handleShowAll}
      >
        <span className="px-2 underline">
          <Translate
            id={showAll ? categoriesKeys.seeLess : categoriesKeys.seeAll}
          />
        </span>
        <div className="ml-2">
          <Icon
            icon={IconsEnum.CHEVRON_DOWN}
            className={cl(
              'h-5 w-5 text-gray-500',
              showAll ? 'transform rotate-180' : null
            )}
          />
        </div>
      </ButtonHelper>
    </div>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavAmountButton;
