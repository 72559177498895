import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategory } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { getChangeProductsFiltersCategoriesData } from '../../../../../../products/utils/getChangeProductsFiltersCategoriesData';

import { ProductPath } from '../../../../../../products/ProductPath';

interface ThreeDStockIndexPageProductsCategoriesNavItemProps {
  category: ThreeDStockIndexPageProductsCategoriesNavCategory;
  categories: ThreeDStockIndexPageProductsCategoriesNavCategory[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
  withChevron?: boolean;
}

function ThreeDStockIndexPageProductsCategoriesNavItem({
  category,
  categories,
  filtersNavBasePath,
  withChevron = false
}: ThreeDStockIndexPageProductsCategoriesNavItemProps) {
  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextLinkHelper
          className="focus:outline-none flex-1 px-2 text-sm"
          dataGa={`3d-stock-menu-products-categories-${
            category.nameEn || category.localizedName
          }`}
          href={ProductPath.indexFiltersCustomBaseQuery(
            filtersNavBasePath,
            getChangeProductsFiltersCategoriesData({
              productCategory: category,
              categories
            }).changeFilters
          )}
        >
          {category.localizedName}
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>

        {withChevron && (
          <Icon
            icon={IconsEnum.CHEVRON_RIGHT}
            className="h-5 w-5 flex-shrink-0"
          />
        )}
      </div>
    </div>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavItem;
