import { useCallback } from 'react';

import { I18nText } from '../../../../../../types';

import {
  DropdownLinksHelper,
  DropdownLinksHelperLinks
} from '../../../../../../helpers/DropdownLinksHelper';
import { Translate } from '../../../../../../helpers/Translate';

import { LocalForage } from '../../../../../../utils/LocalForage';
import { getNavMenuTabPath } from '../../../../../utils/getNavMenuTabPath';

import { PrimaryNavTabsEnum } from '../../../../../hooks/useMenu/types';

interface CompactSecondaryMenuWrapperHeadProps {
  activeMenu?: string;
  i18nMenuTitle: I18nText;
  menuLinks: DropdownLinksHelperLinks;
  tab?: PrimaryNavTabsEnum;
}

function CompactSecondaryMenuWrapperHead({
  activeMenu,
  i18nMenuTitle,
  menuLinks,
  tab
}: CompactSecondaryMenuWrapperHeadProps) {
  const handleClickToLink = useCallback<(linkHref: string) => void>(
    (linkHref) => {
      if (tab) {
        LocalForage.setItem(getNavMenuTabPath(tab), linkHref);
      }
    },
    [tab]
  );

  return (
    <div className="shrink-0 h-16 border-b border-gray-200 dark:border-gray-700 relative">
      <div className="absolute inset-0">
        <div className="flex flex-row w-full h-full items-center px-6">
          <div className="flex items-center max-w-full">
            <div className="text-lg truncate">
              <Translate id={i18nMenuTitle} />
            </div>

            <div className="text-lg text-gray-500 ml-4">/</div>

            <DropdownLinksHelper
              activeLinkName={activeMenu}
              buttonClassName="py-2 pl-4 pr-2 space-x-1 space-x-reverse flex-row-reverse rounded-md inline-flex items-center whitespace-nowrap text-lg font-extrabold truncate hover:bg-gray-500/20 -mr-2 leading-6 focus:ring-base"
              className="relative flex items-center justify-center"
              dataGa={`${activeMenu}-menu-dropdown`}
              links={menuLinks}
              withCheckedLinks
              onClickLink={handleClickToLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompactSecondaryMenuWrapperHead;
