import React, { useState } from 'react';
import size from 'lodash/size';
import take from 'lodash/take';

import { I18nText } from '../../../../../../../types';

import { LifestylesFiltersNavBasePath } from '../../../../../../lifestyles/lifestylesTypes';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategories } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavItem } from '../ThreeDStockIndexPageLifestylesCategoriesNavItem';
import { ThreeDStockIndexPageLifestylesCategoriesNavAmountButton } from '../ThreeDStockIndexPageLifestylesCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { lifestylesKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageLifestylesCategoriesNavListProps {
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategories;
  parentCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategories;
  filtersNavBasePath: LifestylesFiltersNavBasePath;
  allCustomI18nText?: I18nText;
  isShowAll?: boolean;
}

function ThreeDStockIndexPageLifestylesCategoriesNavList({
  lifestyleCategories,
  parentCategories,
  filtersNavBasePath,
  allCustomI18nText,
  isShowAll
}: ThreeDStockIndexPageLifestylesCategoriesNavListProps) {
  const [showAll, setShowAll] = useState(isShowAll);

  const allLifestyles = (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextPureLinkHelper
          className="focus:outline-none flex-1 px-2 text-sm"
          dataGa="3d-stock-menu-lifestyles-categories-all"
          href={filtersNavBasePath}
          i18nText={allCustomI18nText || lifestylesKeys.all}
        />
      </div>
    </div>
  );

  if (size(parentCategories) <= 5) {
    return (
      <>
        {allLifestyles}

        {parentCategories.map((category) => (
          <ThreeDStockIndexPageLifestylesCategoriesNavItem
            key={category.id}
            category={category}
            categories={lifestyleCategories}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? parentCategories
    : take(parentCategories, 5);

  return (
    <>
      {allLifestyles}

      {showedCategories.map((category) => (
        <ThreeDStockIndexPageLifestylesCategoriesNavItem
          key={category.id}
          category={category}
          categories={lifestyleCategories}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}

      {!isShowAll && (
        <ThreeDStockIndexPageLifestylesCategoriesNavAmountButton
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavList;
