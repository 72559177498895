import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID, TeamOwnerNanoID } from '../../../teamsTypes';

import { CompanyWithdrawForm } from '../../forms/CompanyWithdrawForm';
import { useCompanyWithdrawForm } from '../../forms/CompanyWithdrawForm/hooks';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Loading } from '../../../../../helpers/Loading';
import { TooltipAlertMessage } from '../../../../../helpers/TooltipAlertMessage';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { accountsKeys, words } from '../../../../../locales/keys';
interface CompanyWithdrawModalButtonProps {
  companyOwnerNanoId: TeamOwnerNanoID;
  companyNanoId: TeamNanoID;
  withBalance?: boolean;
}

const COMPANY_WITHDRAW_FORM = 'company-withdraw-form';

function CompanyWithdrawModalButton({
  companyOwnerNanoId,
  companyNanoId,
  withBalance
}: CompanyWithdrawModalButtonProps) {
  const {
    companyBalanceAccountsErrorMessage,
    companyBalanceAccountsFetched,
    companyBalance,
    control,
    validationErrors,
    registerFields,
    resetCompanyWithdrawAmountForm,
    companyWithdrawAmountReset,
    companyWithdrawAmountErrorMessage,
    companyWithdrawAmountLoading,
    handleCompanyWithdrawAmount,
    submitDisabled
  } = useCompanyWithdrawForm({
    companyNanoId,
    companyOwnerNanoId
  });

  return (
    <div className="group relative -m-2 p-2 flex items-center space-x-4 rounded-xl bg-blue-500 bg-opacity-0 hover:bg-opacity-10 focus-within:ring-base">
      <div className="shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-blue-500">
        <Icon
          icon={IconsEnum.WITHDRAWAL_OUTLINE}
          className="h-12 w-12 text-white"
        />
      </div>
      <div>
        <FormModalButton
          className="focus:ring-0 focus:ring-offset-0"
          form={COMPANY_WITHDRAW_FORM}
          i18nTitle={words.withdraw}
          i18nSubmitText={words.confirm}
          onOpen={resetCompanyWithdrawAmountForm}
          isLoading={companyWithdrawAmountLoading}
          onClose={companyWithdrawAmountReset}
          onSubmit={handleCompanyWithdrawAmount}
          submitDisabled={submitDisabled}
          disabled={!companyBalanceAccountsFetched}
          buttonChildren={
            <>
              <span className="absolute inset-0" aria-hidden="true"></span>
              <span className="uppercase">
                <Translate id={words.withdraw} />
              </span>
              <span aria-hidden="true"> →</span>
            </>
          }
        >
          <CompanyWithdrawForm
            form={COMPANY_WITHDRAW_FORM}
            accountBalance={companyBalance || 0}
            companyOwnerNanoId={companyOwnerNanoId}
            registerAmount={registerFields.registerAmount}
            amountValidationError={validationErrors.amountValidationError}
            payoneerAccountTypeValidationError={
              validationErrors.payoneerAccountTypeValidationError
            }
            control={control}
          />

          <AlertMessage
            addClassName="mx-4 mt-4"
            message={companyWithdrawAmountErrorMessage}
          />
        </FormModalButton>

        {withBalance ? (
          <div className="mt-1 text-sm text-gray-600 dark:text-gray-400">
            <Translate id={accountsKeys.balance} />
            {':'}
            <br />
            <Loading loaded={companyBalanceAccountsFetched} size="xs">
              {companyBalanceAccountsErrorMessage ? null : (
                <MoneyHelper value={companyBalance || 0} />
              )}
              <TooltipAlertMessage
                message={companyBalanceAccountsErrorMessage}
              />
            </Loading>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CompanyWithdrawModalButton;
