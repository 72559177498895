import React, { useState } from 'react';
import size from 'lodash/size';
import take from 'lodash/take';

import { I18nText } from '../../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategories } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { ThreeDStockIndexPageMaterialsCategoriesNavItem } from '../ThreeDStockIndexPageMaterialsCategoriesNavItem';
import { ThreeDStockIndexPageMaterialsCategoriesNavAmountButton } from '../ThreeDStockIndexPageMaterialsCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { materialsKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageMaterialsCategoriesNavListProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  isShowAll?: boolean;
  materialCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategories;
  parentCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategories;
}

function ThreeDStockIndexPageMaterialsCategoriesNavList({
  allCustomI18nText,
  filtersNavBasePath,
  isShowAll,
  materialCategories,
  parentCategories
}: ThreeDStockIndexPageMaterialsCategoriesNavListProps) {
  const [showAll, setShowAll] = useState(isShowAll);

  const allMaterials = (
    <div className="flex items-center group relative -mx-4 px-4 py-1.5 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
      <NextPureLinkHelper
        className="flex-1 text-sm focus-visible:ring-none"
        dataGa="3d-stock-menu-materials-categories-all"
        href={filtersNavBasePath}
        i18nText={allCustomI18nText || materialsKeys.all}
      />
    </div>
  );

  if (size(parentCategories) <= 5) {
    return (
      <>
        {allMaterials}
        {parentCategories.map((category) => (
          <ThreeDStockIndexPageMaterialsCategoriesNavItem
            key={category.id}
            category={category}
            categories={materialCategories}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? parentCategories
    : take(parentCategories, 5);

  return (
    <>
      {allMaterials}
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageMaterialsCategoriesNavItem
          key={category.id}
          category={category}
          categories={materialCategories}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}

      {!isShowAll && (
        <ThreeDStockIndexPageMaterialsCategoriesNavAmountButton
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavList;
