import React, { Fragment, ReactNode, useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import { GADataType, I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { ModalOptions, useModal } from '../../modals/hooks/useModal';

import { FormModal } from '../../modals/FormModal';

import { PureTooltipIconButtonHelper } from '../PureTooltipIconButtonHelper';
import { PureIconButtonHelper } from '../PureIconButtonHelper';
import { ButtonHelper } from '../ButtonHelper';

import {
  FormModalButtonDefaultProps,
  FormModalLeftButtonsRenderProps
} from './FormModalButton.types';
import { PureButtonHelper } from '../PureButtonHelper';

interface FormModalButtonProps {
  children: ReactNode;
  form: string;
  className?: string;
  buttonChildren?: ReactNode;
  i18nText?: I18nText;
  i18nTextClassName?: I18nText;
  isModalOpen?: boolean;
  icon?: IconsEnum;
  iconClassName?: string;
  tooltipSingleton?: boolean;
  modalSize?: 'md' | 'xl' | '2xl' | '5xl' | 'xs' | 'sm' | 'full';
  onCancel?: () => void;
  hideOnCancel?: boolean;
  leftButtonsChildren?:
    | ReactNode
    | ((data: FormModalLeftButtonsRenderProps) => ReactNode);
}

function FormModalButton({
  afterSubmit,
  buttonChildren,
  buttonsWrapperClassName,
  children,
  className,
  dataGa,
  disabled,
  form,
  hideOnCancel = true,
  i18nCancelText,
  i18nSubmitText,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  id,
  isLoading,
  isModalOpen = false,
  leftButtonsChildren,
  modalSize,
  onCancel,
  onClose,
  onOpen,
  onSubmit,
  onToggle,
  preventModalClose,
  submitButtonColor,
  submitDisabled,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: FormModalButtonProps &
  FormModalButtonDefaultProps &
  ModalOptions &
  GADataType) {
  const modal = useModal({
    isModalOpen,
    onOpen,
    onClose,
    onToggle,
    onSubmit,
    afterSubmit,
    preventModalClose
  });

  const { isOpen, showModal, hideModal, handleSubmit, isClosing } = modal;

  const handleCancel = useCallback(() => {
    onCancel?.();
    hideOnCancel && hideModal();
  }, [hideModal, hideOnCancel, onCancel]);

  const pureI18nTextButton = i18nText ? (
    <PureButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      onClick={showModal}
    />
  ) : null;

  const pureIconButton = icon ? (
    <PureIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      icon={icon}
      iconClassName={iconClassName}
      onClick={showModal}
    />
  ) : null;

  const tooltipI18nTextButton = tooltipI18nText ? (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      icon={icon}
      iconClassName={iconClassName}
      onClick={showModal}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
    />
  ) : null;

  const button = buttonChildren ? (
    <ButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      onClick={showModal}
    >
      {buttonChildren}
    </ButtonHelper>
  ) : null;

  return (
    <Fragment>
      {button || tooltipI18nTextButton || pureIconButton || pureI18nTextButton}
      <FormModal
        submitDisabled={submitDisabled || isClosing}
        form={form}
        handleSubmit={handleSubmit}
        hideModal={hideModal}
        handleCancel={handleCancel}
        i18nCancelText={i18nCancelText}
        i18nSubmitText={i18nSubmitText}
        i18nTitle={i18nTitle}
        id={id}
        isLoading={isLoading}
        isOpen={isOpen}
        submitButtonColor={submitButtonColor}
        modalSize={modalSize}
        buttonsWrapperClassName={buttonsWrapperClassName}
        leftButtonsChildren={
          isFunction(leftButtonsChildren)
            ? leftButtonsChildren(modal)
            : leftButtonsChildren
        }
      >
        {children}
      </FormModal>
    </Fragment>
  );
}
export default FormModalButton;
