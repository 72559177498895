import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';

import { ClassName, GADataType } from '../../types';
import {
  DropdownLinksHelperLinkName,
  DropdownLinksHelperLinks
} from './DropdownLinksHelper.types';
import { Icon } from '../Icon';
import { IconsEnum } from '../../assets/icons/types';

import { DropdownHelper } from '../dropdowns/DropdownHelper';
import { NextPureLinkHelper } from '../links/NextPureLinkHelper';

import { NextLinkHelper } from '../links/NextLinkHelper';
import { Translate } from '../Translate';

import { PopoverPlacement } from '../Popover/popoverConstants';

interface DropdownLinksHelperProps {
  activeLinkName: DropdownLinksHelperLinkName;
  buttonClassName?: ClassName;
  className?: ClassName;
  links: DropdownLinksHelperLinks;
  withCheckedLinks?: boolean;
  onClickLink?: (linkHref: string) => void;
}

function DropdownLinksHelper({
  activeLinkName,
  buttonClassName,
  className,
  dataGa,
  links,
  onClickLink,
  withCheckedLinks = false
}: DropdownLinksHelperProps & GADataType) {
  const activeLink = find(links, ['name', activeLinkName]);

  const dropdownLinks = withCheckedLinks ? links : without(links, activeLink);

  if (isEmpty(links)) {
    return null;
  }

  return (
    <DropdownHelper
      buttonClassName={
        buttonClassName ||
        'py-2 pl-4 pr-2 space-x-1 space-x-reverse flex-row-reverse rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-lg font-extrabold truncate hover:bg-gray-500/20 -mr-2'
      }
      className={className}
      dataGa={dataGa}
      disabled={isEmpty(dropdownLinks)}
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      i18nText={activeLink?.i18nText}
      icon={isEmpty(dropdownLinks) ? null : IconsEnum.SELECTOR}
      iconClassName="h-6 w-6 p-0.5"
      popoverClassName="relative min-w-48 z-50 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
    >
      {dropdownLinks.map((link) =>
        withCheckedLinks ? (
          <NextLinkHelper
            className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
            dataGa={`${dataGa}-${link.name}-link`}
            href={link.href}
            key={link.name}
            onClick={() => onClickLink?.(link.href)}
          >
            <span className="mr-auto">
              <Translate id={link.i18nText} />
            </span>

            {link.name === activeLinkName && (
              <span className="text-blue-600 pl-1.5">
                <Icon icon={IconsEnum.CHECK} className="h-5 w-5" />
              </span>
            )}
          </NextLinkHelper>
        ) : (
          <NextPureLinkHelper
            className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
            dataGa={`${dataGa}-${link.name}-link`}
            href={link.href}
            i18nText={link.i18nText}
            key={link.name}
          />
        )
      )}
    </DropdownHelper>
  );
}

export default DropdownLinksHelper;
