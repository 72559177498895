import { IconsEnum } from '../../../../../assets/icons/types';

import { Icon } from '../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../helpers/Translate';

import { LibraryPath } from '../../../../libraries/LibraryPath';
import { librariesKeys } from '../../../../../locales/keys';

function CompactMenuCreateLibraryLink() {
  return (
    <NextLinkHelper
      className="focus:ring-base py-2 pl-2 pr-4 space-x-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-pink-500 hover:bg-pink-600 shadow-sm hover:shadow-md"
      dataGa="3d-stock-menu-create-library-link"
      href={LibraryPath.create()}
      aria-hidden="true"
    >
      <Icon className="h-6 w-6 p-0.5" icon={IconsEnum.PLUS_OUTLINE} />
      <span>
        <Translate id={librariesKeys.createYourOwnLibrary} />
      </span>
    </NextLinkHelper>
  );
}

export default CompactMenuCreateLibraryLink;
